import $ from 'jquery';

import 'what-input';


// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
//window.jQuery = $;




//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


//$(document).foundation();
/*
jQuery(document).ready(function(){
  var heroSlider = jQuery('.heroSlider');
  heroSlider.owlCarousel({
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    //nav: true,
    mouseDrag: false,
    touchDrag: true,
    items:1,
    loop:true,
    autoplay:true,
    dots:true,
    navText:['<span class="icon icon-chevron-thin-left" aria-hidden="true"></span>','<span class="icon icon-chevron-thin-right" aria-hidden="true"></span>'], //we will be using font awesome icon here
  });
  */
